



















import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import EntranceStudentHistoryHeader from '@/components/organisms/EntranceStudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentHistoryTotal from '@/components/organisms/StudentHistoryTotal.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import { FormatResultSummaries } from '@/models/api/resultSummaries'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import { EntranceInfo } from '@/models/api/history/entrance.ts'
import _ from 'lodash'

@Component({
  components: {
    SidebarSwitcher,
    EntranceStudentHistoryHeader,
    ColoredBox,
    StudentHistoryTotal,
    DatePickerIcon,
  },
})
export default class LearningSituation extends Mixins(LocalMoment, ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private totalStatusDatas = {}

  private period = ''

  private periodDate: { start: Date | null; end: Date | null } = { start: null, end: null }
  private entranceData: EntranceInfo = { school: '-', date: '-', countdown: '-' }

  private onChangeRangeDate(params: { start: moment.Moment; end: moment.Moment }) {
    if (params.start > params.end) {
      alert('選択期間が不正です')
      return
    }
    this.getResultSummaries({ start: params.start.format('YYYY-MM-DD'), end: params.end.format('YYYY-MM-DD') })
  }

  private async created(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    this.period = this.defaultPeriod()
    this.periodDate = this.defaultPeriodDate()
    await this.getResultSummaries(this.defaulrtPeriodParam())
    await this.setEntranceData()
    Vue.prototype.$loading.complete()
  }

  private defaultPeriod(): string {
    return this.beforeMonths(1).format('LL') + '~' + this.today().format('MM月DD日')
  }

  private defaultPeriodDate(): { start: Date; end: Date } {
    return { start: this.beforeMonths(1).toDate(), end: this.today().toDate() }
  }

  private defaulrtPeriodParam(): { start: string; end: string } {
    return { start: this.beforeMonths(1).format('YYYY-MM-DD'), end: this.today().format('YYYY-MM-DD') }
  }

  private async getResultSummaries(params: { start: string; end: string }) {
    Vue.prototype.$http.httpWithToken
      .get(`/history/resultSummaries/${this.userId}`, {
        params: { classModeCode: 'NY', startAt: params.start, endAt: params.end },
      })
      .then((res: any) => {
        this.totalStatusDatas = FormatResultSummaries(res.data)
      })
  }

  private async setEntranceData(): Promise<void> {
    Vue.prototype.$http.httpWithToken
      .get(`/history/entranceInformation`, {
        params: { userId: this.userId },
      })
      .then((res: any) => {
        if (!_.isEmpty(res.data)) this.entranceData = res.data
      })
  }
}
